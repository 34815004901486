.col-e {
    background-color: #d2f3d4 !important;
}

.col-s {
    background-color: #ffdbdb !important;
}

.col-b {
    background-color: #b7dcfc !important;
}

.kardex-table thead tr th {
    font-size: smaller;
    font-weight: 400;
}

.kardex-table tbody tr td {
    border-right: solid 1px rgb(145, 145, 145) !important;
    border-top: solid 0px white !important;
    color: darkslategray;
    white-space: nowrap;
    font-size: x-small;
    font-weight: 500;
}

.kardex-noinfo-text {
    animation: kardex-noinfo-text-animation 3s ease-in;
}


@keyframes kardex-noinfo-text-animation {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }

    30% {
        transform: translateY(0px);
        opacity: 1;
    }

    80% {
        transform: translateY(0px);
        opacity: 1;
    }

    100% {
        transform: translateY(20px);
        opacity: 0;
    }
}