.menuoptionslist {
    list-style: none;
}

.menuoptionslist div {
    display: inline;
}

.grdUsers th {
    background-color: #4a5a7e;
    color: white;
    box-shadow: inset 1px 1px 2px white;
}

.searchUserGrid {
    width: 30%;
    min-width: 50px;
    display: inline;
}

.btn-Search {
    margin-left: 2px;
    display: inline;
}

.UserForm {
    width: 80%;
    margin-left: 10%;
}

.FormGroup-Section1 input {
    width: 150px;
    border: 1px solid #ccc;
}

.UserForm input[type="checkbox"] {
    box-shadow: 0px 0px 3px black;
}

.FormGroup-Section2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.FormGroup-Section2 input {
    width: 48%;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    padding: 10px;
}

@media (max-width: 800px) {
    .UserForm {
        width: 100%;
        margin-left: 0px;
    }
}