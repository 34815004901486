

.purchase-details-container {
    animation: purchase-details-animation 0.5s ease-in-out;
}

@keyframes purchase-details-animation {
    0%{
        transform: scale(0.7);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }

}