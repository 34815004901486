.logo-home {
    animation: logo-animation 2s ease-in-out;
    width: 300px;
}

.label-home {
    animation: label-home-animation 3s ease-in-out;
    font-size: larger;
    color: rgb(48, 147, 48);
    font-weight: 400;
}

@keyframes label-home-animation {
    0% {
        opacity: 0;
        transform: translateX(-150px);
    }

    70% {
        opacity: 0;
        transform: translateX(-150px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes logo-animation {
    0% {
        opacity: 0;
        transform: translateX(150px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}