.vendors-Form * {
    margin-bottom: 10px;
}

.vendors-Tabele-Container {
    border-left: 1px solid rgb(148, 148, 148);
}

.table-col-Type-check input{
    margin-left: 45%;
}