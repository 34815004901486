.transaction-Inventories-Form {
    width: 80%;
    margin-left: 10%;
}

.transaction-Inventories-Form div {
    justify-content: space-around;
}

#label-fileupload-input-invoices {
    color: dimgray;
}


#label-fileupload-input-invoices:hover {
    text-shadow: 1px 1px 1px rgb(167, 167, 167);
    text-decoration: underline;
}

.items-inventory-mov-table {
    width: 80%;
    margin-left: 10%;
}