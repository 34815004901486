.userOptions {
  position: absolute;
  right: 5vw;
}

.navbar-custom {
  /* background-color: #303A50 !important; */
  background-color: rgb(53, 53, 66) !important;
  flex-wrap: wrap !important;
  box-shadow: inset 0px -1px 3px white;
}

.navbar-custom .nav-item.dropdown:hover .dropdown-menu {
  background-color: white;
}

.navbar-custom .nav-item.dropdown:hover .dropdown-menu .dropdown-item {
  color: #000000;
}

.iconP {
  border: 1px solid rgb(124, 123, 123);
  background-color: rgb(90, 90, 90);
  box-shadow: 0px 0px 5px beige;
  border-radius: 50px;
  width: 30px;
  height: 30px;
}

.navbar-custom .nav-item.dropdown .dropdown-menu .dropdown-item p {
  white-space: normal;
  word-wrap: break-word;
  font-size: 13px;
  margin-left: 15px;
}

.ItemTypeNoti {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ItemTypeNoti:hover {
  background-color: #303a50;
}

.ItemTypeNoti:hover p {
  color: white;
}