.client-formclient {
    width: 80%;
    margin-left: 10%;
}

.client-formclient .row {
    margin-bottom: 20px;
    /* justify-content: space-around; */
}

@media (max-width:750px) {
    .client-formclient{
        width: 100%;
        margin-left: 0%;
    }        
}