.goHomeFromErrorLink{
    color: white;
    font-style: italic;
    text-decoration: underline;
}

.goHomeFromErrorLink:hover{
    color: rgb(5, 5, 59);
}

.ErrorContainer{
    color: white;
    background-color: #0167a5;
    display: grid;
    place-items: center;
    height: 100vh;
}

.clouds{
    background-color: rgb(68, 112, 54);
    width: 100%;
    height: 100%;
}

.cloud{
    border-radius: 50%;
    background-color: rgba(37, 37, 37, 0.418);
    width: 100%;
    height: 100%;
}