.logoLogin {
    position: absolute;
    margin-top: 50px;
    margin-left: 50px;
}

.login-container {
    width: 100vw;
    min-width: 268px;
    height: 100vh;
    min-height: 318px;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.login {
    width: 270px;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 40%;
    top: 25%;
    border-radius: 10px;
}

.formLogin {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.603);
    color: white;
    border-radius: 10px;
    border: 1px solid black;
    overflow: hidden;
    animation: fadeIn 0.5s ease-out;
}

.formLogin:hover {
    box-shadow: 0px 0px 20px rgb(203, 202, 202);
}


.formLogin button {
    width: 100%;
    margin-top: 2px;
}


.formLogin input {
    margin-top: 2px;
}


.login a {
    text-decoration: none;
    color: white;
}

.login a:hover {
    text-decoration: none;
    color: rgb(65, 65, 128);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeIn {
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

.BtnPswRst {
    color: white !important;
}

@media screen and (max-width: 720px) {
    .login {
        left: 0;
        width: 100%;
    }

    .logoLogin {
        position: absolute;
        margin: 0;
        left: calc(50% - 73px);
        top: 50px;
    }

}