@tailwind base;
@tailwind components;
@tailwind utilities;



.infoContainers {
  border: 1px solid rgb(214, 214, 214);
  background-color: white;
  width: 90% !important;
  height: 100% !important;
  min-height: 600px;
  box-shadow: 10px 100px 500px rgb(103, 103, 103);
  animation: fadeInHS 0.5s ease-out;
  margin: 0px 5vw 0px 5vw;
  padding: 25px;
}

.infoContainers-black {
  border: 1px solid rgb(214, 214, 214);
  background-color: #45454d;
  color: white;
  width: 90% !important;
  height: 100% !important;
  min-height: 600px;
  box-shadow: 10px 100px 500px rgb(103, 103, 103);
  animation: fadeInHS 0.5s ease-out;
  margin: 0px 5vw 0px 5vw;
  padding: 25px;
}


@keyframes fadeInHS {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }

  95% {
    opacity: 1;
    transform: translateY(0);
  }
}

.waitForm {
  position: absolute;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: calc(50vh - 75px);
  left: calc(50vw - 250px);
  width: 500px;
  height: 150px;
  background-color: #4C4C4C;
  color: white;
  border-radius: 10px;
}

.waitFormContainerFull {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block;
  place-items: center;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.852);
  overflow-y: scroll;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="date"],
textarea,
select {
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px dimgray;
}

table.GenericTable {
  box-shadow: 0px 0px 5px #313131;
}

div:has(table.overedflow) {
  overflow-y: auto;
  overflow-x: auto;
  white-space: nowrap;
}

table.GenericTable tbody tr td {
  font-size: 300;
  background-color: white !important;
}

table.GenericTable.black tbody tr td {
  font-size: 300;
  background-color: #4a4a4a !important;
  color: white;

}

table.GenericTable tbody tr td img {
  display: inline-block;
}

table.GenericTable th {
  background-color: rgb(30 41 59);
  color: white;
  text-align: start;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

table.GenericTable {
  border-radius: 10px;
}

table.GenericTable th:first-child {
  border-top-left-radius: 10px;
}

table.GenericTable th:last-child {
  border-top-right-radius: 10px;
}

table.GenericTable tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

table.GenericTable tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.Containerdescripcion {
  color: rgb(145, 145, 145);
}

button:hover {
  transform: scale(1.05, 1.05);
  box-shadow: 2px 2px 1px gray;
}

.tables-container {
  border-left: 1px solid rgb(148, 148, 148);
}


@media (max-width: 800px) {
  div.login {
    padding: 0;
  }

  .infoContainers {
    border: 1px solid rgb(201, 200, 200);
    padding: 0;
    height: 100% !important;
    width: 100vw !important;
    box-shadow: 0px 10px 500px rgb(192, 192, 192);
    animation: fadeInHS 0.5s ease-out;
    margin: 0px 0px 0px 0px;
  }

  .waitForm {
    position: fixed;
    display: flex;
    top: 20%;
    left: 0;
    width: 100%;
  }

  .waitFormContainerFull {
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.852);
    z-index: 2;
  }
}