.FormVegetables * {
    margin-bottom: 10px;
}

.FormVegetables button{    
    width: auto;
}

.SearchVegetable{
    width: 250px;
    margin-bottom: 10px;
    margin-right: 5px;
    display: inline-block;
}

.GridSection{
    border-left: 1px solid rgb(184, 183, 183);
    height: 100% ;
}